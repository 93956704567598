import React from "react";
import Upcomingprojects from "./UpcomingProjects/Upcomingprojects";
import Carousel from "./Carousel/Carousel";
import Featured from "./Featuredprojects/Featured";
import Exclusive from "./Exclusive/Exclusive";
import Investinplots from "./Invest/Investinplots";
import WhyWic from "./WhyWic/WhyWic";
import Ourleadership from "./Ourleadership/Ourleadership";
import Slider from "./Events/Events";
const HomePage = () => {
 
  return (
    <div className="backgroundimg">
      {/* <ValidationForm /> */}
      <Carousel />
     <div id="upcoming-projects">
      <Upcomingprojects />
      </div>
     <div id="exclusive-projects">
      <Exclusive />
      </div>
      <div id="featured-projects">
      <Featured type="Featured" />
      </div>
      <Investinplots />
      <WhyWic />
      <Slider />
      <Ourleadership />
    </div>
  );
};
export default HomePage;