import React from 'react'

const Blogs = () => {
  return (
    <div>
      <h1>Blogs</h1>
    </div>
  )
}

export default Blogs
