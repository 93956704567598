import React, { useState, useEffect } from "react";
import "./Projects.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faMoneyCheckAlt, faBuilding,} from "@fortawesome/free-solid-svg-icons";
import RecentProject from "./RecentProject";
import axios from "axios";
import { Link } from "react-router-dom";
const Projects = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    axios
      .get(`https://apis.womekiinvestorsclub.co.in/createpost`)
      .then((response) => {
        const filteredPosts = response.data.filter(
          (post) => post.category === "WIC"
        );
        setPosts(filteredPosts);
      })
      .catch((error) => console.error("Error fetching posts:", error));
  }, []);
  const uniquePosts = posts.filter(
    (post, index, self) =>
      index === self.findIndex((p) => p.projectname === post.projectname)
  );
  return (
    <>
      <RecentProject />
      <div className="container">
        <h2 className="heading-verticals">OUR PROJECTS</h2>
        <div className="container">
          <div className="row  ">
            {uniquePosts.map((post) => (
              <div key={post._id} className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center" >
                <div className="card project-card ">
                  <div className="project-card-img-div">
                    <img className="project-imgs" src={`https://apis.womekiinvestorsclub.co.in/${post.files}`} alt="gg"/>
                  </div>
                  <div className="project-card-text-div">
                    <h4 className="project-heading">{post.projectname}</h4>
                    <p className="project-text">
                      {post.address}{" "}
                      <FontAwesomeIcon
                        className="card-location-icon"
                        icon={faLocationDot}
                      />
                    </p>
                    <p className="project-price">
                      {post.price}{" "}
                      <FontAwesomeIcon
                        className="card-currency-icon" icon={faMoneyCheckAlt}/>
                    </p>
                  </div>
                  <div className="project-seemore-div">
                    <p className="project-type">
                      <FontAwesomeIcon className="card-build-icon" icon={faBuilding} />{post.type}</p>
                    <Link to={`/product/${post._id}`}><p className="project-seemore-btn">See More Details</p></Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Projects;