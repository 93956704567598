import React from 'react';
import "./Ourleadership.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Ourleadership = () => {
  return (
    <div className='container '>
      <h4 className='heading-verticals'>OUR LEADERSHIP</h4>
      <div className='leader-main-div'>
        <div className='ourleadershipcontainer'>
        
          {/* <div className='abt-team-card-div'>
              <div className='ceo-img-div'>
                <img className=" abt-ceo-img" src="./rohitkhari.webp" alt="cap" />
              </div>
              <div className="textdiv">
                <h5 className=" abt-ceo-title mt-2">Mr. Rohit Khari</h5>
                <div className='d-flex justify-content-center'>
                  <p className="abt-ceo-text">Co-Founder</p>
                  <img className='abt-wic-logo' src='/logo.webp' alt='logo' /></div>
                <div className=' abt-social-div'>
                  <a href='https://www.facebook.com/rohit.khari.1?mibextid=LQQJ4d' target="_blank" rel="noopener noreferrer">
                    <div className='abt-social-icon-div'>
                      <FontAwesomeIcon className='fb-icon' icon={faFacebookF} />
                    </div></a>
                  <a href='https://www.linkedin.com/in/rohit-khari-883b00bb/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app' target="_blank" rel="noopener noreferrer">
                    <div className='abt-social-icon-div'>
                      <FontAwesomeIcon className='linkedin-icon ' icon={faLinkedinIn} />
                    </div> </a>
                  <a href='https://www.instagram.com/kharirohitgurjjar/?igsh=ZjU5ZnRtNHJmMGw3' target="_blank" rel="noopener noreferrer">
                    <div className='abt-social-icon-div'>
                      <FontAwesomeIcon className='insta-icon' icon={faInstagram} />
                    </div></a>
                </div>
              </div>
            </div> */}
          
          
            <div className=' abt-team-card-div'>
              <div className='ceo-img-div'>
                <img className="abt-ceo-img" src="/Ankit.webp" alt="cap" />
              </div>
              <div className="ceo-text-div">
                <h5 className=" abt-ceo-title mt-2">Mr. Shiv K. Singh</h5>
                <div className='d-flex justify-content-center'>
                  <p className=" abt-ceo-text">Sales Director</p>
                  <img className='abt-wic-logo' src='/logo.webp' alt='logo' /></div>
                <div className=' abt-social-div'>
                  <a href='https://www.facebook.com/profile.php?id=100003384279376' target="_blank" rel="noopener noreferrer">
                    <div className='abt-social-icon-div'>
                      <FontAwesomeIcon className='fb-icon' icon={faFacebookF} />
                    </div></a>
                  <a href='https://www.linkedin.com/in/shiv-singh-988364267/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app' target="_blank" rel="noopener noreferrer">
                    <div className='abt-social-icon-div'>
                      <FontAwesomeIcon className='linkedin-icon ' icon={faLinkedinIn} />
                    </div> </a>
                  <a href='https://www.instagram.com/shivsinghtonger/?igsh=MXdvYzRxYWc2dWY4bQ%3D%3D' target="_blank" rel="noopener noreferrer">
                    <div className='abt-social-icon-div'>
                      <FontAwesomeIcon className='insta-icon' icon={faInstagram} />
                    </div></a>
                </div>
              </div>
            </div>
         
            {/* <div className=' abt-team-card-div'>
              <div className='ceo-img-div'>
                <img className=" abt-ceo-img" src="./parassir.webp" alt="cap" />
              </div>
              <div className="">
                <h5 className="abt-ceo-title mt-2">Mr. Paras Goyal</h5>
                <div className='d-flex justify-content-center'>
                  <p className="abt-ceo-text">Director Sales</p>
                  <img className='abt-wic-logo' src='/logo.webp' alt='logo' /></div>
                <div className=' abt-social-div'>
                  <div className='abt-social-icon-div'>
                    <FontAwesomeIcon className='fb-icon' icon={faFacebookF} />
                  </div>
                  <a href='https://www.linkedin.com/in/paras-g-6439ab21/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' target="_blank" rel="noopener noreferrer">
                    <div className='abt-social-icon-div'>
                      <FontAwesomeIcon className='linkedin-icon ' icon={faLinkedinIn} />
                    </div></a>
                  <a href='https://www.instagram.com/paras.ge/?igsh=eTF1YW53aWpod2xs' target="_blank" rel="noopener noreferrer">
                    <div className='abt-social-icon-div'>
                      <FontAwesomeIcon className='insta-icon' icon={faInstagram} />
                    </div></a>
                </div>
              </div>

            </div> */}
        </div>
      </div>
    </div>
  )
}

export default Ourleadership
