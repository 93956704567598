import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage/HomePage';
import About from './About/ABout';
import Projects from './PROJECTS/Projects';
import Contact from './Contact/Contact';
import Blogs from './Blogs/Blogs';
import Footer from './Footer/Footer';
import Gallery from './Gallery/Gallery';
import ScrollTop from './scrolltop/Scrolltop';
import ProjectDetail from './PROJECTS/ProjectDetail';
import NavBar from './Navbar/NavBar';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Router>
        <ScrollTop />
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/About-womeki-investors-club" element={<About />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/product/:_id" element={<ProjectDetail />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
