import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Detail.css'
import { useParams } from 'react-router-dom';
import Upcomingprojects from '../HomePage/UpcomingProjects/Upcomingprojects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { faBath, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
const ProjectDetail = () => {
  const { _id } = useParams();
  const [product, setProduct] = useState(null);
  useEffect(() => {
    axios.get(`https://apis.womekiinvestorsclub.co.in/createpost`)
      .then(response => {
        const matchedProduct = response.data.find(item => item._id === _id);
        setProduct(matchedProduct);
      })
      .catch(error => {
        console.error('Error fetching project data:', error);
      });
  }, [_id]);
  return (
    <div className='container pt-5'>
      {product && (
        <>
        <h2 className='Headingg'>{product.projectname}</h2>
    <div className='main-img-detail' >
    <img className='detail-img' src={`https://apis.womekiinvestorsclub.co.in/${product.files}`}  alt='dd' />
    </div>
    <div className='mt-5' >
      <div className='row ' >
        <div className=' col-12 detail-project-from d-flex justify-content-between' >          
          <div className='mini-detail col-lg-8 ' >
            <h2 className='detain-heading' >Project Detail</h2>
            <div className='detail-page-description' dangerouslySetInnerHTML={{__html:product.content}} />
          </div>
          <div className=' mt-15 mini-details col-lg-3   ' >
            <input className=' detail-page-input' placeholder='Name' />
            <input className=' detail-page-input' placeholder='Email' />
            <input className=' detail-page-input' placeholder='Phone' />
            <textarea className=' detail-page-inputs' id="w3review" name="w3review" rows="4" cols="20" />
            <button className='button-detail-submit' >SUBMIT</button>
          </div>
        </div>
        <div className='col-lg-8 mt-5 col-sm-12 details-key d-flex '  >
          <div className=' col-3 innerdetailkey' >
           <div className='mini-key-mini1' >
           <FontAwesomeIcon className='mt-2 aminities-icon' icon={faBuilding} />
            <p className='key-points'>Property Type</p>
           </div>
           <div className='mini-key-mini2' >{product.type}</div>
          </div>
          <div className=' col-3 innerdetailkey' >
          <div className='mini-key-mini1' >
          <FontAwesomeIcon className='mt-2 aminities-icon' icon={faIndianRupeeSign} />
          <p className='key-points' >Price</p>
          </div>
           <div className='mini-key-mini2' >{product.Price}</div>
          </div>
          <div className=' col-3 innerdetailkey' >
          <div className='mini-key-mini1' >
          <FontAwesomeIcon className='mt-2 aminities-icon' icon={faBath} />
          <p className='key-points'>Bathroom</p>
          </div>
           <div className='mini-key-mini2' ></div>
           <div className='mini-key-mini2' ></div>
          </div>
          <div className=' col-3 innerdetailkey' >
          <div className='mini-key-mini1' >
          <FontAwesomeIcon className='mt-2 aminities-icon' icon={faMapLocationDot} />
          <p className='key-points'>Area</p>
          </div>
           <div className='mini-key-mini2' ></div>
           <div className='mini-key-mini2' ></div>
          </div>
        </div>
      </div>
       </div>
     <Upcomingprojects/>
        </>
     )}
    </div>
  );
};
export default ProjectDetail;