import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import "./Contact.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Contact = () => {
  const form = useRef();
  const [nameInput, setNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [swiperInstance, setSwiperInstance] = useState(null);

  // Handle form submission for emailjs
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_jvoxnkp', 'template_r1aqy12', form.current, 'vpigVXDjQF8Z0u1eF')
      .then((result) => {
        console.log(result.text);
        form.current.reset();
        setNameInput('');
        setPhoneInput('');
      }, (error) => {
        console.log(error.text);
      });
  };

  // Alert after form submission
  const shoot = () => {
    alert("Thanks for your response. We will connect to you shortly.");
  };

  // Handle name change, allowing only letters and spaces
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    if (/^[A-Za-z\s]+$/.test(inputValue) || inputValue === '') {
      setNameInput(inputValue);
    }
  };

  // Handle phone number input, limiting to 10 digits
  const handleNumberChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    const limitedNumericValue = numericValue.slice(0, 10);
    setPhoneInput(limitedNumericValue); 
  };

  // Handle Swiper slide change
  const handleSlideChange = (index) => {
    if (swiperInstance) {
      swiperInstance.slideTo(index); 
    }
  };

  return (
    <div className='container pb-5'>
      <div className='row'>
        <div className='col-12'>
          <h4 className='heading-verticals'>CONTACT US</h4>
          <p className='contact-content'>
            Your perfect partner for your real estate pivot. Get in touch. The WIC team is dedicated to providing you with the highest level of service and support. Whether you have a question about one of our properties, a concern about the buying process, or need general information about our services, we are here to assist you. You can reach out to us by phone, email, or by filling out the contact form below. Our team will get back to you as soon as possible to address your queries and provide you with the necessary information.
          </p>
        </div>
        <div className='col-md-6 col-sm-12'>
          <img className='contact-img-div' src='/contact.webp' alt='cont' />
          <h6 className='cont-img-text text-center'>Our Team will respond to your Query</h6>
          <a href="tel:9911140024">
            <div className="mt-4 text-center btn-contact d-flex justify-content-center">
              <p className='contacttel'>+91 9911140024</p>
            </div>
          </a>
        </div>
        <div className='col-md-6 col-sm-12 mt-5'>
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
          >
            {/* First Slide: Contact Form */}
            <SwiperSlide>
              <form ref={form} onSubmit={sendEmail}>
                <div className="form-group box-cont">
                  <input
                    required
                    type="text"
                    className="form-control border-0 contact-text-placeholder"
                    name='to_name'
                    placeholder='Enter your Name'
                    value={nameInput}
                    onChange={handleNameChange}
                  />
                </div>
                <div className="form-group box-cont">
                  <input
                    required
                    type="email"
                    className="form-control border-0 contact-text-placeholder"
                    name='from_name'
                    placeholder='Enter your Email'
                  />
                </div>
                <div className="form-group box-cont">
                  <input
                    type="number"
                    className="form-control border-0 contact-text-placeholder"
                    name='message'
                    placeholder='Enter your Number'
                    value={phoneInput}
                    onChange={handleNumberChange}
                  />
                </div>
                <div className="form-group box-cont">
                  <input
                    type="number"
                    className="form-control border-0 contact-text-placeholder"
                    name='message'
                    placeholder='Enter your Budget'
                  />
                </div>
                <div className="form-group box-cont-msg">
                  <textarea
                    className="form-control mb-3 border-0 contact-text-placeholder"
                    rows="5"
                    name='message'
                    placeholder='Enter your Query'
                  ></textarea>
                </div>
                <input onClick={shoot} className='contact-submit-button border-0' type='submit' value="Send" />
              </form>
            </SwiperSlide>

            {/* Second Slide: Registration Form */}
            <SwiperSlide>
            <form ref={form} onSubmit={sendEmail}>
            <div className="form-group box-cont">
                  <input required type="text" className="form-control border-0 contact-text-placeholder" name="fname" placeholder='Enter your First Name' />
                </div>
                <div className="form-group box-cont">
                  <input required type="text" className="form-control border-0 contact-text-placeholder" name="lname" placeholder='Enter your Last Name' />
                </div>
                <div className="form-group box-cont">
                  <input required type="text" className="form-control border-0 contact-text-placeholder" name="gender" placeholder='Enter your Gender' />
                </div>
                <div className="form-group box-cont">
                  <input required type="text" className="form-control border-0 contact-text-placeholder" name="profession" placeholder='Enter your Profession' />
                </div>
                <div className="form-group box-cont">
                  <input required type="text" className="form-control border-0 contact-text-placeholder" name="contact" placeholder='Enter your Contact Number' />
                </div>
                <div className="form-group box-cont">
                  <input required type="email" className="form-control border-0 contact-text-placeholder" name="email" placeholder='Enter your Email Id' />
                </div>
                <div className="form-group box-cont">
                  <input required type="text" className="form-control border-0 contact-text-placeholder" name="location" placeholder='Enter your Location' />
                </div>
                <div className="form-group box-cont">
                  <input required type="text" className="form-control border-0 contact-text-placeholder" name="referredby" placeholder='Referred By' />
                </div>
                <button type='submit' name="submit" className='contact-submit-button border-0 mt-5'>Send</button>
              </form>
            </SwiperSlide>
          
            {/* Slide Navigation Buttons */}
            <div className=' d-flex align-items-left'>
              <button className='me-5 popup_btn' onClick={() => handleSlideChange(0)}>Contact Us</button>
              <button className='popup_btn' onClick={() => handleSlideChange(1)}>Register as Channel Partner</button>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Contact;
