import React, { useState, useEffect } from "react";
import "./Projects.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faMoneyCheckAlt,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Link } from "react-router-dom";
const RecentProject = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    axios
      .get(`https://apis.womekiinvestorsclub.co.in/createpost`)
      .then((response) => {
        setPosts(response.data);
      })
      .catch((error) => console.error("Error fetching posts:", error));
  }, []);
  return (
    <div className="container-fluid">
      <div className="container">
        <h4 className="heading-verticals">RECENT PROJECT</h4>
        <div className="row  ">
          {posts
            .filter(
              (post) => post.category === "WIC" && post.subcategory === "Recent"
            )
            .map((post) => (
              <div
                key={post._id}
                className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center"
              >
                <div className="card project-card ">
                  <div className="project-card-img-div">
                    <img
                      className="project-imgs"
                      src={`https://apis.womekiinvestorsclub.co.in/${post.files}`}
                      alt="gg"
                    />
                  </div>
                  <div className="project-card-text-div">
                    <h4 className="project-heading">{post.projectname}</h4>
                    <p className="project-text">
                      {post.address}{" "}
                      <FontAwesomeIcon
                        className="card-location-icon"
                        icon={faLocationDot}
                      />
                    </p>
                    <p className="project-price">
                      {post.price}{" "}
                      <FontAwesomeIcon
                        className="card-currency-icon"
                        icon={faMoneyCheckAlt}
                      />
                    </p>
                  </div>
                  <div className="project-seemore-div">
                    <p className="project-type">
                      <FontAwesomeIcon
                        className="card-build-icon"
                        icon={faBuilding}
                      />
                      {post.type}
                    </p>
                    <Link to={`/product/${post._id}`}>
                      <p className="project-seemore-btn">See More Details</p>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default RecentProject;