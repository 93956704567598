import React, { useRef } from "react";
import "./Events.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
const data = [
  {
    image: "Holi-5.webp",
  },
  {
    image: "Holi-2.webp",
  },
  {
    image: "Holi-19.webp",
  },
  {
    image: "Holi-18.webp",
  },
  {
    image: "Holi-4.webp",
  },
  {
    image: "Holi-20.webp",
  },
  {
    image: "./diwali1.webp",
  },
  {
    image: "diwali2.webp",
  },
  
  {
    image: "diwali15.webp",
  },
  {
    image: "diwali17.webp",
  },
  {
    image: "diwali18.webp",
  },
 
  {
    image: "diwali20.webp",
  },
  {
    image: "diwali21.webp",
  },
  {
    image: "diwali22.webp",
  },
  
  {
    image: "diwali24.webp",
  },
  {
  image :"events-img4.webp",
},
{
  image :"events-img3.webp",
}
];
const Slider = () => {
  const sliderRef = useRef(null);
  const handleNextClick = () => {
    const items = sliderRef.current.querySelectorAll(".item");
    sliderRef.current.appendChild(items[0]);
  };
  const handlePrevClick = () => {
    const items = sliderRef.current.querySelectorAll(".item");
    sliderRef.current.prepend(items[items.length - 1]);
  };
    return (
    <div className="container" >
      <h2 className="heading-verticals">EVENTS</h2>
      <div className="d-flex align-items-center justify-content-center">
    <div className="mainanimation">
      <div className="containerss">
        <div className="slide" ref={sliderRef}>
          {data.map((item, index) => (
            <div
              key={index}
              className="item" 
              style={{ backgroundImage: `url(${item.image})` }}
            >
              </div>
          ))}
        </div>
        <div className="nav-btn">
        <button className="prev" onClick={handlePrevClick}><FontAwesomeIcon className="arrow-icon" icon={faArrowLeft} /></button>
      <button className="next" onClick={handleNextClick}><FontAwesomeIcon className="arrow-icon" icon={faArrowRight} /></button>
      </div>
      </div>
      </div>
    </div>
    </div>
  );
};
export default Slider;