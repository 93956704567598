import React, { useState } from 'react';
import './Gallery.css'
import data from './Gallery.json'
import { Navigation, Autoplay, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const Gallery = () => {
  const [showHoliContent, setShowHoliContent] = useState(false);
  const holiContent = () => {
      setShowHoliContent(!showHoliContent);
    }; 
    const [showDiwaliContent, setShowDiwaliContent] = useState(false);
    const diwaliContent = () => {
        setShowDiwaliContent(!showDiwaliContent);
      }; 
      const [showEventContent, setShowEventContent] = useState(false);
    const eventContent = () => {
        setShowEventContent(!showEventContent);
      };  
  return (
    <div className='container'>
    <div className='gallery-main-div'>
    
        <div className='row'>
        {data.Section1.map((NewData, index) => {
            return(
                <div key={index} className='col-12'>
                    <h4 className='gallery-title'>{NewData.Title}</h4>
                    <p className='gallery-content'>{NewData.Content}</p>
                </div>
            )
        })}
        </div>
        <h4 className='heading-verticals'>HOLI PARTY 2024</h4>
        <div className='holi-main-div'>
         <div className='container'>
        <div className='row'>
         <div className='gallery-seemore-div'>
         <p className='gallery-seemore' onClick={holiContent}> {showHoliContent ? 'See less' : 'See more'}</p>
         </div>
         {showHoliContent ? ( 
         data.Section4.map((NewData, index) => {
            return(
                <div key={index} className='col-md-4 col-sm-12'>
                <img className='gallery-image mt-3' src={NewData.URL} alt='gg'/>
                </div>  
            )
        })
        ) : (
      
         <Swiper
      modules={[Navigation,Autoplay, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={3}
      autoplay={{
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false
       }}
      navigation={{ clickable: true }}
      breakpoints={{
        320: {
          slidesPerView: 1,
        },
        480: {
            slidesPerView: 2,
          },
          768: {
              slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3,
          }
      }}
     >
        {data.Section4.map((NewData, index) => {
            return(
           
                <SwiperSlide>
                <div key={index} className='col-md-4 col-sm-12'>
                <img className='gallery-image' src={NewData.URL} alt='gg'/>
                </div>
                </SwiperSlide>
            )
        })}
        </Swiper>
     )}
     </div>
   </div>
   </div>

        <h4 className='heading-verticals'>DIWALI PARTY 2023</h4>
        <div className='diwali-main-div'>
         <div className='container'>
        <div className='row'>
         <div className='gallery-seemore-div'>
         <p className='gallery-seemore' onClick={diwaliContent}> {showDiwaliContent ? 'See less' : 'See more'}</p>
         </div>
         {showDiwaliContent ? ( 
         data.Section2.map((NewData, index) => {
            return(
                <div key={index} className='col-md-4 col-sm-12'>
                <img className='gallery-image mt-3' src={NewData.URL} alt='gg'/>
                </div>  
            )
        })
        ) : (
      
         <Swiper
      modules={[Navigation,Autoplay, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={3}
      autoplay={{
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false
       }}
      navigation={{ clickable: true }}
      breakpoints={{
        320: {
          slidesPerView: 1,
        },
        480: {
            slidesPerView: 2,
          },
          768: {
              slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3,
          }
      }}
     >
        {data.Section2.map((NewData, index) => {
            return(
           
                <SwiperSlide>
                <div key={index} className='col-md-4 col-sm-12'>
                <img className='gallery-image' src={NewData.URL} alt='gg'/>
                </div>
                </SwiperSlide>
            )
        })}
        </Swiper>
     )}
     </div>
   </div>
   </div>
   <h4 className='heading-verticals'>EVENTS</h4>
<div className='diwali-main-div pb-5'>
<div className='container'>
        <div className='row'>
          <div className='gallery-seemore-div'>
            <p className='gallery-seemore' onClick={eventContent}> {showEventContent ? 'See less' : 'See more'}</p></div>
            {showEventContent ? (
            data.Section3.map((NewData, index) => {
            return(
                <div key={index} className='col-md-4 col-sm-12'>
                <img className='gallery-image mt-3' src={NewData.URL} alt='gg'/>
                </div>
            )
        })
        ) : (
        
            <Swiper
      modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={3}
      autoplay={{
      delay: 3000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false
   }}
      navigation={{ clickable: true }} 
      breakpoints={{
        320: {
          slidesPerView: 1,
        },
       
        480: {
          slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        }
      }}
    >
        {data.Section3.map((NewData, index) => {
            return(
                
                <SwiperSlide>
                <div key={index} className='col-md-4 col-sm-12'>
                <img className='gallery-image mt-3' src={NewData.URL} alt='gg'/>
                </div>
                </SwiperSlide>
            )
        })}
         </Swiper>
         )}
         </div>
        </div>
        </div>
        </div>
        </div>

   
  )
}

export default Gallery
