import React from 'react'
import './WhyWic.css'
import data from './WhyWic.json'
const WhyWic = () => {
  return (
       <div className=" container">
       <h2 className='heading-verticals'>WHY WIC</h2>
       <p className='whywic-ptext'>REASONS TO INVEST IN REAL ESTATE WITH US</p>
    <div className="whywicsection row">
            {data.Section1.map((NewData, index) => {
                return(
                    <div className='whywicbgtext'>
                    <div key={index} className=' col-md-6 col-sm-12 d-flex why-col-div '>
                        <h6 className='whycontent'>{NewData.Content}</h6>
                        <img className='why-img' src={NewData.Image} alt='kk'/>
                    </div>
                    </div>
                )
            })}
        </div>
        </div>                  
  )
}
export default WhyWic