import React from 'react'
import './Carousel.css'

const Carousel = () => {
  return (
//     <div className="home-main-div">
//       <div className="homelandingpage">
//           <div className="container" >       
//   <div className='row'>
//   <div className='col-12 backgroundbestdeal '>
//   <img className='offers-img me-3' src='/bxs_offer.webp' alt='off'/>
//     <h5 className='best-deal'>GET THE BEST DEAL</h5>
//     <img className='offers-img ms-3' src='/bxs_offer.webp' alt='off'/>
//   </div>
// </div>
// <div className='row '>
//  <div className='col-12 d-flex mt-5 last-col-div'>
//     <div className='product-col '>
//     <h5 className="number-head p-2">1000+</h5>
//     <p class="sell-text">PRODUCT SELL</p>
//   </div>
 
//   <div className='product-col '>
//     <h5 className="number-head p-2">50Cr</h5>
//     <p class="sell-text">PRODUCT SELL</p>
//     </div>
 
  
//   <div className='product-col '>
//     <h5 className="number-head p-2">1000+</h5>
//     <p class="sell-text">LOCATIONS SELL</p>
//     </div>
   

 
//   <div className='product-col '>
//     <h5 className="number-head p-2">50Cr</h5>
//     <p class="sell-text">TOTAL <p>SELL</p> </p>
//     </div>
//     </div>
//   </div>
// </div>
// </div>
// </div>

<div>
<video width="100%" height="auto" autoPlay muted loop>
        <source src="../hero-video.mp4" type="video/mp4" />
      </video>
</div>

  )
}

export default Carousel
